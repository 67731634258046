import { pointsDiscutesTypes } from '@/views/Patients/Dossier/Constants'
import { Component, Vue } from 'vue-property-decorator'
import Alert from '@/components/shared/Alert.vue'
import { ErrorService } from '@/services/error.service'
import { ValidationObserver } from 'vee-validate'
import { Subscription } from 'rxjs'
import { PediatryService } from '@/services/patient-pediatry-service'
import { PediatryEntourageModel } from '@/models/podiatry-observation-model'
import { dossierPatientModel } from '@/models/dossier-response-model'
import Commons from '@/components/shared/Helpers/commons'
import Confirm from '@/components/shared/Confirm/confirm.vue'

@Component({
  components: {
    Alert,
    Confirm,
    ValidationObserver
  }
})
export default class PediatryEntourage extends Vue {
  private pediatryService = PediatryService.getInstance()
  private subscription!: Subscription

  public pointsDiscutesTypes = pointsDiscutesTypes
  public entourage: PediatryEntourageModel = {}
  public isEdit = false
  public selection: number[] = []
  public errorMessages: string[] = []
  public entourageErrors = ''
  public isSaving = false
  public dossierId!: string

  public showConfirm = false
  public routeNextCallback = null as any
  public redirectPath = null as string | null
  public hasSaved = false

  beforeRouteLeave (to, from, next) {
    if (!this.hasSaved) {
      if (this.redirectPath == null) {
        this.redirectPath = to
      }
      this.showConfirm = true
      this.routeNextCallback = next
      return false
    } else {
      next()
    }
  }

  public beforeUnloadHandler (e) {
    e.returnValue = 'Vos modifications ne sont peut-être pas enregistrées, voulez-vous vraiment quitter cette page ?'
    return ''
  }

  public confirmCallback (value: boolean) {
    this.showConfirm = false
    if (value && this.routeNextCallback) {
      this.routeNextCallback()
    }
  }

  public beforeDestroy () {
    window.removeEventListener('beforeunload', this.beforeUnloadHandler)
  }

  public mounted () {
    window.addEventListener('beforeunload', this.beforeUnloadHandler)
    this.dossierId = this.$route.params.dossierId
    const entourageId = this.$route.params.id
    this.subscription = this.pediatryService.pediatryEntourageSelected$.subscribe(selectedEntourage => {
      this.entourage = selectedEntourage
      this.selection = [...this.entourage.pointsDiscutesTypeIds ?? []]
      if (entourageId === 'new' && this.dossierId) {
        this.entourage.dossierId = this.dossierId
      }
      if (!this.entourage.id && entourageId !== 'new') {
        const path = `/patient`
        this.$router.push(path)
      }
      this.isEdit = !!selectedEntourage.id
    })
  }

  public ensureUserNavTitle (patientDossier: dossierPatientModel) {
    if (patientDossier.guid) {
      Commons.updateDossierName()
    }
  }

  public async save () {
    if (!this.selection.length) {
      this.entourageErrors = 'Le/les points discutés est manquante'
      return
    }
    const observer = this.$refs.observer as InstanceType<typeof ValidationObserver>
    const isValid = await observer.validate()
    this.entourageErrors = ''
    if (isValid) {
      this.isSaving = true
      this.entourage.pointsDiscutesTypeIds = this.selection
      const results = await this.pediatryService.AddPediatryEntourage(this.dossierId, this.entourage)
        .catch(async (errs) => {
          const res = await ErrorService.handleError(errs)
          this.errorMessages = res.errors
        })
        .finally(() => {
          this.isSaving = false
        })
      if (results) {
        this.hasSaved = true
        this.redirectPath = null
        this.goToPediatryEntourages()
      }
    } else {
      Commons.focusFirstComponentWithError(observer, this.$refs, 'Ref')
    }
  }

  public hideAlert () {
    this.errorMessages = []
  }

  public goBackButton () {
    this.redirectPath = null
    // act as if we saved, so that we bypass the confirm dialog
    this.hasSaved = true
    this.goToPediatryEntourages()
  }

  public async goToPediatryEntourages (forceReset = false) {
    const observer = this.$refs.observer as InstanceType<typeof ValidationObserver>
    observer.reset()
    this.errorMessages = []
    if (forceReset) {
      this.redirectPath = null
    }
    if (this.redirectPath == null) {
      this.$router.go(-1)
    } else {
      this.$router.push(this.redirectPath)
    }
  }

  public destroyed () {
    this.subscription.unsubscribe()
    this.pediatryService.updatePediatryEntourageSelected({ date: Commons.GetTodayFormatted() })
  }
}
